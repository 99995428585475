<template>
  <div class="pageBox">
    <NavBar :title="$t('appDown.default[0]')" :page-number="-1"/>
    <div class="topDiv">
      <img src="~@/assets/img/skinbitcoin/logo.png" width="180px" style="text-align: center"></img>
    </div>
    <div style="display: flex; align-items: center; flex-direction: column;">
     <div style="margin-top: 100px; width: 360px;">
       <van-button class="border-grey" block type="info" style="font-size: 16px;">
         <div style="display: flex; flex-direction: row;align-items: center;">
           <div><img src="~@/assets/img/skinbitcoin/andorid.png" width="30"></div>
           <div>{{$t('appDown.default[1]')}}</div>
         </div>
         </van-button>
     </div>
      <div style="margin-top: 20px; width: 360px;">
        <van-button class="border-grey" block type="info" style="font-size: 16px">
          <div style="display: flex; flex-direction: row; align-items: center;">
            <div><img src="~@/assets/img/skinbitcoin/apple.png" width="30"></div>
            <div>{{$t('appDown.default[2]')}}</div>
          </div>
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2"
export default {
  name: 'AppDown',
  components: {

  },
  props: {},
  data() {
    return {
      saveN: 0,
      isPlus: window.plus,
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
  }
}
</script>
<style scoped>
.pageBox {
  margin: 0 auto;
  text-align: center;
}
.topDiv{
  width: 100%;
  height: 60px;
  text-align: center;
  margin-top: 200px;
}

.van-button {
  width: 100%;
}
</style>